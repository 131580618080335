<template>
  <div>
    <el-tooltip
      content="Must Filter By Type & CashbackId"
      :disabled="isFiltersSelected"
      placement="top"
      effect="dark"
    >
      <el-dropdown @command="handleSheetExport" :disabled="!isFiltersSelected">
        <el-button
          :class="`u-${$store.state.isRTL ? 'right' : 'left'}-margin--1x`"
          type="primary"
          icon="el-icon-bottom"
          size="large"
          :disabled="!isFiltersSelected"
        >
          {{ $t("GlobalExportFileButtonLabel") }}
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            :command="{
              fileName: 'Exported Segmented Users',
              action: undefined,
              exportType: 'exportSegmentedUsersListTemplate',
            }"
            >Segmenting Users</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
    </el-tooltip>
    <el-dropdown @command="handleSheetExport">
      <el-button
        size="large"
        icon="el-icon-download"
        type="info"
        :class="`u-${$store.state.isRTL ? 'right' : 'left'}-margin--1x`"
        plain
      >
        {{ $t("GlobalTemplateDownloadButtonLabel") }}
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
          :command="{
            fileName: 'Segmented Users List Template',
            action: 'create',
            exportType: 'exportSegmentedUsersListTemplate',
          }"
          >Segmented Users List Template</el-dropdown-item
        >
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import { cashbackService } from "../../services/cashback.service";
import { utils } from "../../utils";

export default {
  name: "cashbackExportButtons",
  props: { filter: { type: Object, required: false } },
  computed: {
    isFiltersSelected() {
      const url = window.location.href;
      const hasProducts = url.includes("/cashback/products");
      const { type: cashbackType, cashbackId } = this.filter;

      if (hasProducts) {
        // If the URL contains "products"
        return cashbackId !== undefined && cashbackId !== null;
      } else {
        // If the URL does not contain "products"
        return (
          cashbackType !== undefined &&
          cashbackType !== null &&
          cashbackId !== undefined &&
          cashbackId !== null
        );
      }
    },
  },
  methods: {
    async handleSheetExport({ action, fileName, exportType }) {
      const url = window.location.href;
      const hasProducts = url.includes("/cashback/products");
      try {
        const response = await cashbackService[exportType](action, {
          type: hasProducts && "product",
          ...this.filter,
        });

        if (response && response.data) {
          const responseData = response.data;
          let blob = new Blob([responseData], {
            type: response.headers["content-type"],
          });
          utils.downloadBlob(blob, fileName, "xlsx");

          this.$message({
            message: "file downloaded successfully",
            type: "success",
            duration: 4000,
          });
        }
      } catch (error) {
        console.log(error);
        this.$message({
          message:
            utils.decodeArrayBuffer(error.response.data)?.message ??
            utils.errorMessageExtractor(error),
          type: "error",
          duration: 2000,
        });
      }
    },
  },
};
</script>

<style></style>
