export const redemptionTableCols = [
  { prop: "entityId", label: "Entity ID" },
  { prop: "EntityName", label: "Entity Name" },
  { prop: "retailerName", label: "Retailer Name" },
  { prop: "phone", label: "Phone Number" },
];

export const upcomingCashbackTableCols = [{ prop: "id", label: "ID" }];

export const productsCashbackTableCols = [
  { prop: "id", label: "ID", width: "100" },
  { prop: "entityId", label: "WHS ID", width: "100" },
  { prop: "entityName", label: "WHS Name" },
  { prop: "productName", label: "Product Name" },
  { prop: "unitName", label: "Unit" },
  { prop: "cashbackMaxPerOrder", label: "Max Quantity" },
];

const cashbackTypeDropDownData = [
  {
    label: "General",
    value: "general",
  },
  {
    label: "Provider",
    value: "provider",
  },
  {
    label: "T-Number",
    value: "tNumber",
  },
  {
    label: "Bundle",
    value: "bundle",
  },
];

export const cashbackMetrics = [
  {
    key: "type",
    label: "Type",
    compName: "dynamicDropdownMetric",
    meta: {
      data: cashbackTypeDropDownData.map((item) => {
        return {
          key: item.value,
          label: item.label,
        };
      }),
      placeholder: "Select Type",
    },
  },
  {
    key: "cashbackId",
    label: "Cashback ID",
    compName: "stringMetric",
    meta: {
      placeholder: "Input Cashback ID",
    },
  },
];

export const cashbackProductMetrics = [
  {
    key: "cashbackId",
    label: "Cashback ID",
    compName: "stringMetric",
    meta: {
      placeholder: "Input Cashback ID",
    },
  },
];
